<template>
  <div class="home">
    <div
      class="primary d-none d-sm-none d-md-block d-lg-block d-xl-block top-bar"
    >
      <v-row justify="space-around">
        <v-col cols="12" sm="12" md="1" lg="1">
          <span class="white--text"></span>
        </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" class="mt-2 mb-2">
          <!-- <span class="white--text"
            ><span class="white--text"
              ><v-icon color="#FFF" small>mdi-phone</v-icon> 93843 57572</span
            ></span
          > -->
          <v-btn color="white" class="mr-2" outlined @click="priceListOpen"
            >Download Price List</v-btn
          >
        </v-col>
        <v-col cols="12" sm="12" md="2" lg="2"> </v-col>
        <v-col cols="12" sm="12" md="1" lg="2"> </v-col>
        <v-col cols="12" sm="12" md="3" lg="3" class="mt-2 mb-2">
          <v-btn
            color="white"
            class="mr-2"
            outlined
            href="retail-payment-confirm"
            >Payment Confirmation</v-btn
          >

          <v-icon  size="45" @click="paymentYoutubeopen" color="white" > mdi-youtube </v-icon>
          <!-- <span class="white--text"
            ><v-icon color="#FFF" small>mdi-email</v-icon>
            kannancrackers2015@gmail.com</span
          > -->
        </v-col>
        <!-- <v-col cols="12" sm="12" md="1" lg="1">
          <span class="white--text"></span>
        </v-col> -->
      </v-row>
    </div>

    <v-app-bar color="white" fixed height="125px" class="mt-md-8 mt-lg-8">
      <v-container>
        <v-row
          justify="space-around"
          id="container"
          ref="container"
          class="mt-5"
        >
          <v-col
            cols="12"
            md="6"
            lg="6"
            large
            class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
          >
            <v-breadcrumbs :items="menus" divider=" ">
              <template v-slot:item="{ item }">
                <v-breadcrumbs-item :href="item.href">
                  <v-btn small color="secondary" class="mb-2">{{
                    item.text
                  }}</v-btn>
                </v-breadcrumbs-item>
              </template>
            </v-breadcrumbs>
          </v-col>
          <div class="d-block d-sm-block d-md-none d-lg-none d-xl-none">
            <v-breadcrumbs :items="menus"></v-breadcrumbs>
          </div>
          <v-col
            cols="12"
            sm="12"
            md="6"
            lg="3"
            class="d-none d-sm-none d-md-block d-lg-block d-xl-block"
          >
            <img alt="Vue logo" src="../assets/logo.png" height="60" contain />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="3"
            class="d-flex justify-space-between flex-row text-wrap"
          >
            <v-btn icon v-if="cartQty" class="mb-8" @click="popupCheckout">
              <v-badge
                color="black"
                :content="cartQty"
                overlap
                offset-x="10"
                offset-y="10"
              >
                <v-icon color="black">mdi-cart-outline</v-icon>
              </v-badge>
            </v-btn>
            <v-btn icon v-if="cartQty == ''" class="mb-8">
              <v-badge
                color="black"
                content="0"
                overlap
                offset-x="10"
                offset-y="10"
                bordered
              >
                <v-icon color="black">mdi-cart-outline</v-icon>
              </v-badge>
            </v-btn>
            <v-btn text class="mb-8">
              <div class="d-none d-sm-none d-md-block d-lg-block d-xl-block">
                NET TOTAL :
              </div>
              ₹ {{ productSalesPrice }}
            </v-btn>
            <v-btn outlined color="primary" @click="contactDialog = true"
              ><v-icon color="primary" small class="mr-2">mdi-phone</v-icon
              >Contacts</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-dialog v-model="contactDialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Contact Details
        </v-card-title>
        <v-card-text>
          <p class="mb-1 mt-5">
            <v-icon color="primary" small>mdi-phone</v-icon> Order Confirmation
            : 93843 57572, 80569 03599, 73971 53601, 97504 44600, 75388 97572
          </p>
          <p class="mb-1">
            <v-icon color="primary" small>mdi-phone</v-icon> Payment
            Confirmation: 97905 72926, 97900 39111, 97900 25111, 63854 27540,
            63854 27539
          </p>
          <p class="mb-1 mt-5">
            <v-icon color="primary" small>mdi-phone</v-icon> Dispatch:
            96003317037548840091,7540091520,9597631302, 8870061845
          </p>
          <p class="mb-1">
            <v-icon color="primary" small>mdi-phone</v-icon> Complaint:
            9942449196,7540011846,7530027560,7530034370
          </p>
          <p class="mb-1">
            <v-icon color="primary" small>mdi-phone</v-icon> Address
            Confirmation: 9790431702,8148251846
          </p>
          <p class="mb-1">
            <v-icon color="primary" small>mdi-phone</v-icon> Transport L L R
            confirmation : 7708367545,9597681301
          </p>
          <p class="mb-1">
            <v-icon color="primary" small>mdi-phone</v-icon> Owner Number :
            9384755471
          </p>
          <p class="mb-1">
            <v-icon color="primary" small>mdi-email</v-icon>
            kannancrackers2015@gmail.com
          </p>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined @click="contactDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-container>
      <div class="productdiv" ref="productdiv1"></div>
      <v-card class="mt-1 mx-auto" height="150" elevation="0">
        <div
          class="
            d-flex
            flex-column
            fill-height
            justify-center
            align-center
            text-black
          "
        >
          <h1 class="primary--text">Retail Payment Confirmation</h1>
        </div>
      </v-card>

      <v-card class="mx-auto mb-5" elevation="0" outlined>
        <!--alert box-->
        <v-alert
          text
          prominent
          type="success"
          v-if="successResponse"
          class="mt-4"
        >
          <div v-for="(error, i) in paymentResponse" :key="i">
            <h3 v-html="error"></h3>
          </div>
        </v-alert>

        <v-alert text prominent type="error" v-if="errorResponse">
          <div v-for="(error, i) in paymentResponse" :key="i">
            <h3 v-html="error"></h3>
          </div>
        </v-alert>

        <v-form ref="fileForm" class="ma-5">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="orderno"
                :error-messages="ordernoErrors"
                label="Order No"
                required
                outlined
                @input="$v.orderno.$touch()"
                @blur="$v.orderno.$touch()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="mobileno"
                :error-messages="mobilenoErrors"
                label="Mobileno"
                required
                outlined
                @input="$v.mobileno.$touch()"
                @blur="$v.mobileno.$touch()"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <v-file-input
                label="Screen Shot"
                v-model="screenshot"
                required
                outlined
                :rules="imageRules"
                accept="image/png, image/jpeg, image/jpg"
                @change="screenshotSelected"
                :error-messages="screenshotErrors"
                @input="$v.screenshot.$touch()"
                @blur="$v.screenshot.$touch()"
              ></v-file-input>
            </v-col>

            <v-divider></v-divider>
            <v-col cols="12" sm="12" md="12">
              <v-btn
                class="float-right"
                outlined
                color="primary"
                @click="fileUpload"
                width="150"
                height="40"
              >
                <span class="black--text">UPLOAD</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>

    <!--footer Component-->
    <div>
      <footerCommon />
    </div>
  </div>
</template>

<style scoped>
.top-bar {
  position: fixed;
  width: 100%;
  z-index: 111;
}

.productdiv {
  margin-top: 100px !important;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { VUE_WEB_BASEURL } from "../utils/api";
export default {
  name: "RetailPaymentConfirm",
  data: () => ({
    productRate: "0.00",
    productOfferPrice: "0.00",
    productSalesPrice: "0.00",
    contactDialog: false,
    cartQty: 0,
    menus: [
      {
        text: "Retail Price",
        disabled: false,
        href: "/",
      },
      {
        text: "Family pack",
        disabled: false,
        href: "familypack",
      },
      {
        text: "Wholesale case",
        disabled: false,
        href: "wholesale",
      },
      {
        text: "Whole sale",
        disabled: false,
        href: "diwali",
      },
      {
        text: "Gift box",
        disabled: false,
        href: "giftbox",
      },
      {
        text: "About Us",
        disabled: false,
        href: "aboutus",
      },
      {
        text: "Contact Us",
        disabled: false,
        href: "contactus",
      },
    ],
    orderno: null,
    mobileno: null,
    screenshot: null,
    screenshotFile: null,
    imageRules: [
      (value) => !!value || "This Field is required",
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Image size should be less than 2 MB!",
    ],
  }),
  validations: {
    orderno: { required },
    mobileno: { required },
    screenshot: { required },
  },
  computed: {
    ...mapGetters(["authStatus", "getPaymentStatus", "paymentStatusResponse"]),
    ordernoErrors() {
      const errors = [];
      if (!this.$v.orderno.$dirty) return errors;
      !this.$v.orderno.required && errors.push("Orderno is required");
      return errors;
    },
    mobilenoErrors() {
      const errors = [];
      if (!this.$v.mobileno.$dirty) return errors;
      !this.$v.mobileno.required && errors.push("Mobileno is required");
      return errors;
    },
    screenshotErrors() {
      const errors = [];
      if (!this.$v.screenshot.$dirty) return errors;
      !this.$v.screenshot.required && errors.push("Screenshot is required");
      return errors;
    },
    errorResponse: function () {
      return this.getPaymentStatus === "error";
    },
    successResponse: function () {
      return this.getPaymentStatus === "success";
    },
    paymentResponse: function () {
      return this.paymentStatusResponse;
    },
  },
  created() {},
  methods: {
    ...mapActions(["retailPaymentConfirm"]),
    screenshotSelected(event) {
      console.log(event);
      let screenshot = event;
      // this.screenshotname = event.name;
      let reader = new FileReader();
      reader.readAsDataURL(screenshot);
      reader.onload = (event) => {
        this.screenshotFile = event.target.result;
      };
    },
    async fileUpload() {
      this.$v.$touch();
      if (
        this.$v.orderno.$invalid &&
        this.$v.mobileno.$invalid &&
        this.$v.screenshot.$invalid
      ) {
        console.log("ERROR");
      } else {
        // console.log(this.screenshotFile);

        setTimeout(() => {
          const orderData = {
            orderno: this.orderno,
            mobileno: this.mobileno,
            screenshot: this.screenshotFile,
          };
          //console.log(orderData);
          this.retailPaymentConfirm(orderData);

          //this.chechoutbtn=false;
        }, 500);
      }
    },
    paymentYoutubeopen(){
      window.open('https://youtu.be/OGeCoK6bEeg', "_blank");
    },
    priceListOpen() {
      window.open(VUE_WEB_BASEURL + "retail-product/", "_blank");
    },
  },
};
</script>
