<template>
  <v-footer dark padless>
    <v-card flat tile class="white--text text-center footer-card">
      <v-card-text class="white--text pt-0">
        <v-row justify="space-around" id="container" ref="container">
          <v-col cols="12" sm="3" md="3" class="text-center pa-5">
            <div v-html="getFooterDetails.address1"></div>
            <v-divider></v-divider>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="3" md="3" class="text-center pa-5">
            <div v-html="getFooterDetails.address2"></div>
            <v-divider></v-divider>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="3" md="3" class="text-center pa-5">
            <div v-html="getFooterDetails.address3"></div>
            <v-divider></v-divider>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="3" md="3" class="text-center pa-5">
            <div v-html="getFooterDetails.address4"></div>
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12" sm="3" md="3" class="text-center">
            <img
              v-if="getFooterDetails.qrcode1"
              alt="QR code"
              :src="
                imageUrl + 'public/storage/qrcode/' + getFooterDetails.qrcode1
              "
              height="250"
              contain
            />
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="3" md="3" class="text-center">
            <img
              v-if="getFooterDetails.qrcode2"
              alt="QR code"
              :src="
                imageUrl + 'public/storage/qrcode/' + getFooterDetails.qrcode2
              "
              height="250"
              contain
            />
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="3" md="3" class="text-center">
            <img
              v-if="getFooterDetails.qrcode3"
              alt="QR code"
              :src="
                imageUrl + 'public/storage/qrcode/' + getFooterDetails.qrcode3
              "
              height="250"
              contain
            />
          </v-col>
          <v-divider vertical></v-divider>
          <v-col cols="12" sm="3" md="3" class="text-center">
            <img
              v-if="getFooterDetails.qrcode4"
              alt="QR code"
              :src="
                imageUrl + 'public/storage/qrcode/' + getFooterDetails.qrcode4
              "
              height="250"
              contain
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-divider></v-divider>

      <v-card-text class="white--text text-center">
        {{ new Date().getFullYear() }} — <strong>Kannancrackers</strong> —
        <strong>Developed By Smartxpose Technologies</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<style>
.footer-card {
  width: 100%;
}
</style>

<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

import { VUE_WEB_BASEURL } from "../utils/api";

export default {
  name: "FooterCommon",
  data: () => ({
    imageUrl: "",
  }),
  computed: {
    ...mapGetters(["getFooterDetails"]),
  },
  created() {
    this.fetchFooter();
    this.imageUrl = VUE_WEB_BASEURL;
  },
  methods: {
    ...mapActions(["fetchFooter"]),
  },
};
</script>
